import {SPECS as SdkSpecs} from '@wix/wixstores-client-storefront-sdk/dist/es/src/constants';

export const SPECS = {
  ...SdkSpecs,
  SUBSCRIPTION_PLAN_USE_TPA_RADIO_BUTTON: 'specs.stores.SubscriptionPlanUseTpaRadioButton',
  USE_LIGHTBOXES: 'specs.stores.UseLightboxes',
  USE_DROPDOWN_TPA_LABEL: 'specs.stores.UseDropdownTpaLabel',
  CART_TOOLTIP_WITHOUT_NUMBER: 'specs.stores.CartTooltipWithoutNumber',
  EDITOR_OOI: 'specs.stores.EditorGalleryOOI',
  ACTIVE_DISABLED_VARIANTS: 'specs.stores.ActiveDisabledVariants',
  DISABLE_MOBILE_NATIVE_DROPDOWN: 'specs.stores.DisableMobileNativeDropdown',
  FIX_CASHIER_PROPS_PRICE_SHIFTING: 'specs.stores.ProductPageFixCashierPropsPriceShifting',
  UseNewExpressCheckoutButtonsInProductPage: 'specs.stores.UseNewExpressCheckoutButtonsInProductPage',
  ProductPageWishlistWithNewMembersArea: 'specs.stores.ProductPageWishlistWithNewMembersArea',
  SHOULD_USE_COMMON_DISCOUNT_PRICING_METHODS: 'specs.stores.ShouldUseCommonDiscountPricingMethods',
  LOCATION_ONCHANGE_PATH_CHANGE_FOR_EDITOR_SAUSAGE:
    'specs.stores.ProductPageLocationOnChangePathChangeForEditorSausage',
  DROPDOWN_LABEL_FOR: 'specs.stores.ProductPageDropdownLabelFor',
  PRODUCT_PAGE_RICO_DESCRIPTION: 'specs.stores.ProductPageRicoDescription',
  PRODUCT_PAGE_MIGRATE_TO_RICO_DESCRIPTION: 'specs.stores.ProductPageMigrateToRicoDescription',
  PRODUCT_PAGE_WITHOUT_GLOBAL_CSS: 'specs.stores.productPageWithoutGlobalCss',
  NotifyEmailOnCheckout: 'specs.stores.ProductPageNotifyEmailOnCheckout',
  SHOULD_USE_VARIANT_LEVEL_PRE_ORDER_AVAILABLE: 'specs.stores.ShouldUseVariantLevelPreOrderAvailable',
  SHOULD_RESET_QUANTITY_UPON_SELECTION_CHANGE: 'specs.stores.GalleryProductItemResetQuantityUponSelectionChange',
  PRODUCT_PAGE_BREADCRUMBS_LOCALIZE_ALL_PRODUCTS_REFERRING_PAGE:
    'specs.stores.ProductPageBreadcrumbsLocalizeAllProductsReferringPage',
  PRODUCT_PAGE_SLOTS: 'specs.stores.ProductPageSlots',
  BREADCRUMBS_AFTER_HYDRATION: 'specs.stores.ProductPageBreadcrumbsAfterHydration',
  EnableDynamicSizeDefaultImage: 'specs.stores.EnableDynamicSizeDefaultImage',
  ShouldUseProductLevelPricePerUnit: 'specs.stores.OOIShouldUseProductLevelPricePerUnit',
  ShouldUseEcomPlatformPublicApiAddToCartInPP: 'specs.stores.ShouldUseEcomPlatformPublicApiAddToCartInPP',
} as const;
